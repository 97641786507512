<template>
	<div class="h-100">
		<CCard class="h-100">
			<CCardBody class="technician-form">
				<div class="shop__header">
					<h5 class="mb-5">
						{{ getTitle }}
					</h5>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<!-- create  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="user_created_date"
										mode="lazy"
									>
										<div class="form-group row">
											<label for="createdDate" class="col-md-2 col-12 col-form-label">{{
												$t("UserFormFields.createdDate")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<date-picker
													id="createdDate"
													v-model="createdDate"
													value-type="format"
													class="w-100"
													:placeholder="$t('UserFormFields.dateFormat')"
													:format="formatDate"
													disabled
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- name  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="user_name"
										:rules="`max:${USER_MANAGEMENT_ROLE.NAME}|requiredExport`"
									>
										<div class="form-group required row">
											<label for="name" class="col-md-2 col-12 col-form-label">{{
												$t("UserFormFields.name")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<input
													id="name"
													v-model.trim="userForm.name"
													type="text"
													class="form-control"
													:placeholder="$t('UserFormFields.name')"
													autocomplete="name"
													:maxlength="USER_MANAGEMENT_ROLE.NAME"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- phone number -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="user_phone"
										:rules="`requiredExport|phone|max:${USER_MANAGEMENT_ROLE.PHONE}`"
									>
										<div class="form-group required row">
											<label for="phone" class="col-md-2 col-12 col-form-label">{{
												$t("UserFormFields.phone")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="phone"
													v-model.trim="userForm.phoneNumber"
													type="nnumbe"
													:oninput="maxLengthPhoneNumber"
													class="form-control"
													:placeholder="$t('UserFormFields.phone')"
													autocomplete="phone"
													:maxlength="USER_MANAGEMENT_ROLE.PHONE"
													@keydown="checkValidPhone($event)"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Email  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="user_email"
										:rules="`requiredExport|max:${USER_MANAGEMENT_ROLE.EMAIL}|isEmail`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("UserFormFields.email")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="email"
													v-model.trim="userForm.email"
													type="text"
													class="form-control"
													:placeholder="$t('UserFormFields.email')"
													autocomplete="email"
													:maxlength="USER_MANAGEMENT_ROLE.EMAIL"
													:disabled="isUpdate"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<!-- role  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="user_role"
										:rules="`requiredExport`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("UserFormFields.role")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<multiselect
													id="role"
													v-model="userForm.role"
													:options="listRole"
													label="name"
													track-by="value"
													:disabled="isUpdate"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="true"
													:placeholder="$t('Label.role')"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<!-- status  -->
								<div v-if="showStatus" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="status"
										name="user_status"
										:rules="`requiredExport`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="email" class="col-md-2 col-12 col-form-label">{{
												$t("UserFormFields.status")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<multiselect
													id="status"
													v-model="userForm.status"
													:options="listStatus"
													label="name"
													track-by="value"
													:disabled="disabledStatus"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="true"
													:placeholder="$t('Label.status')"
												>
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<CRow class="text-center">
							<CCol>
								<button type="button" class="btn btn-outline-danger" @click="cancel">
									{{ $t("Button.Cancel") }}
								</button>
								<button class="px-4 ml-2 btn btn-outline-custom" type="submit">
									{{ $t("Button.Save") }}
								</button>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<CustomModal
			v-if="confirmStatus"
			:title="getTitleModal()"
			:submit-text="$t('Button.Yes')"
			@submit-modal="confirm"
			@cancel-modal="cancelStatus"
		>
			<template #content>
				{{ getBodyModal() }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { USER_MANAGEMENT_ROLE, DATE_VN, USER_STATUS } from "@/shared/plugins/constants"
import moment from "moment"
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("users")
import { startCase, capitalize } from "lodash-es"
import { USER_ROLE } from "../../../shared/plugins/constants"

export default {
	name: "UserForm",
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			USER_MANAGEMENT_ROLE,
			formatDate: DATE_VN,
			confirmStatus: false,
			createdDate: moment(new Date()).format("DD-MM-YYYY"),
			userForm: {
				name: null,
				phoneNumber: null,
				email: null,
				status: null,
				role: null,
			},
			listStatus: [
				{
					name: this.$t("UsersFilter.active"),
					value: USER_STATUS.ACTIVE,
				},
				{
					name: this.$t("UsersFilter.inactive"),
					value: USER_STATUS.DEACTIVATE,
				},
			],
			listRole: [
				{
					name: this.$t("UsersFilter.Admin"),
					value: USER_ROLE.ADMIN,
				},
				{
					name: this.$t("UsersFilter.Qa"),
					value: USER_ROLE.QA,
				},
			],
		}
	},
	computed: {
		...mapState(["userDetail"]),
		getTitle() {
			return this.isUpdate
				? `${startCase(this.userDetail?.name)} ${this.$t("UsersTitle.update")}`
				: this.$t("UsersTitle.NewUser")
		},
		currentUser() {
			return this.$store.state.authentication.currentUser
		},
		showStatus() {
			if (this.isUpdate && this.userDetail?.role !== USER_ROLE.SUPER_ADMIN) {
				return true
			}
			return false
		},
		disabledStatus() {
			if (this.currentUser?.role === USER_ROLE.SUPER_ADMIN) {
				return false
			}
			return true
		},
	},
	watch: {
		userDetail() {
			this.setUpData()
		},
	},
	created() {
		this.setUpData()
	},
	methods: {
		submitForm: async function () {
			if (this.isUpdate && this.userForm.status.value !== this.userDetail.status) {
				return this.showPopup()
			}
			return this.sendData()
		},
		cancel: function () {
			this.$emit("cancel")
		},
		maxLengthPhoneNumber() {
			if (this.userForm.phone && this.userForm.phone.length > USER_MANAGEMENT_ROLE.PHONE) {
				return (this.userForm.phone = this.userForm.phone.slice(0, USER_MANAGEMENT_ROLE.PHONE))
			}
		},
		setUpData() {
			// for update userForm
			if (this.isUpdate && this.userDetail) {
				this.userForm = { ...this.userDetail }
				this.createdDate = this.convertDateToDateVN(this.userForm.createdDate)
				this.userForm.status = this.listStatus.find(
					status => status.value === this.userDetail.status,
				)
				this.userForm.role = {
					name: capitalize(startCase(this.userDetail.role)),
				}
			} else {
				this.userForm.role = {
					name: this.$t("UsersFilter.Qa"),
					value: USER_ROLE.QA,
				}
			}
		},
		sendData() {
			const params = {
				email: this.userForm.email,
				name: this.userForm.name,
				phoneNumber: this.userForm.phoneNumber,
				role: this.userForm.role.value,
			}
			if (this.isUpdate) {
				params["id"] = this.id
				params["status"] = this.userForm.status.value
				delete params.email
			}
			this.$emit("submit", params)
		},
		showPopup() {
			this.$store.commit("set", ["modalShow", true])
			this.confirmStatus = true
		},
		getTitleModal() {
			if (this.userForm.status.value === USER_STATUS.ACTIVE) {
				return this.$t("UserMessage.Header.Active")
			}
			return this.$t("UserMessage.Header.Inactive")
		},
		getBodyModal() {
			if (this.userForm.status.value === USER_STATUS.ACTIVE) {
				return this.$t("UserMessage.Body.Active")
			}
			return this.$t("UserMessage.Body.Inactive")
		},
		confirm() {
			this.sendData()
			this.$store.commit("set", ["modalShow", false])
			this.confirmStatus = false
		},
		cancelStatus() {
			this.confirmStatus = false
		},
	},
}
</script>
<style lang="scss" scoped>
.custom__input {
	width: 100%;
	margin: auto;
}
</style>
